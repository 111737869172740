<template>
  <div></div>
</template>

<script>
export default {
  name: "Logout",
  async created() {
    window.localStorage.clear();
    this.$router.push("/login");
  }
};
</script>

<style></style>
